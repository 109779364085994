<template>
  <div class="join">
    <view-wrapper header-text="Join the<br>Conversation">
      <p>Get behind-the-scenes updates about the filming of <strong>THE WEST IS BURNING</strong>. Be the first to know when and where to watch it. And get in early to make your own short films that you can share.</p>

      <!-- <join-form></join-form> -->
      <klayvio-btn
        class="ml-0"
      />
    </view-wrapper>
  </div>
</template>

<script>
// import JoinForm from '@/components/_forms/JoinForm/JoinForm'
import KlayvioBtn from '@/components/KlayvioBtn/KlayvioBtn'

export default {
  name: 'join',

  components: {
    // JoinForm,
    KlayvioBtn
  }
}
</script>
